import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FiBook } from 'react-icons/fi';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { GroupCard } from '@components';

import { SubscriptionContext } from '@contexts';
import { GetMaximumAllowedUsers, isTrialUser, makeToast } from '@helpers';

import { getAccountCount } from '@services';

import {
  APP_STATUS,
  DEAL_STAGE,
  SOHO_PRODUCT_TYPES,
  TOAST_TYPE
} from '@constants';

export const BasicDetailTab = () => {
  const { subscription } = useContext(SubscriptionContext);
 
  const [totalUser, setTotalUser] = useState(0); //this should pull from the subscription context

  useEffect(() => {
    fetchAndSetTotalUser();
  }, []);

  const fetchAndSetTotalUser = async () => {
    const loadingToastId = makeToast({
      type: TOAST_TYPE.LOADING,
      message: APP_STATUS.INFO.FETCHING_DATA,
      loading: true
    });
     
  
    const usersCount = await getAccountCount(subscriptionId);
    
    toast.dismiss(loadingToastId);
    if (usersCount?.value) {
      setTotalUser(usersCount.value.length);
    }
   
  };

  const { subscriptionId, dealStage, adminAccount, created, tenantIds } =
    subscription;


  const maxAllowedUser = GetMaximumAllowedUsers(subscription);
  
  const trialUser = isTrialUser(subscription.created);
  const dealStageText =
    Object.keys(DEAL_STAGE)[Object.values(DEAL_STAGE).indexOf(dealStage)];

  return (
    <div className="flex justify-between gap-8">
      <GroupCard title="Basic" section="Overview" className="w-2/3">
        <table className="w-full border-collapse items-center bg-transparent">
          <tbody>
            <tr>
              <th className="whitespace-nowrap border-b border-solid border-gray-200 py-4 text-left align-middle text-sm font-semibold">
                Subscription ID
              </th>
              <td className="whitespace-nowrap border-b border-gray-200 text-left align-middle text-sm font-light">
                {subscriptionId}
              </td>
            </tr>
            <tr>
              <th className="whitespace-nowrap border-b border-solid border-gray-200 py-4 text-left align-middle text-sm font-semibold">
                Status
              </th>
              <td className="whitespace-nowrap border-b border-gray-200 text-left align-middle text-sm font-light">
                {dealStageText}
              </td>
            </tr>
            <tr>
              <th className="whitespace-nowrap border-b border-solid border-gray-200 py-4 text-left align-middle text-sm font-semibold">
                Original Registration User
              </th>
              <td className="whitespace-nowrap border-b border-gray-200 text-left align-middle text-sm font-light">
                {adminAccount}
              </td>
            </tr>
            <tr>
              <th className="whitespace-nowrap border-b border-solid border-gray-200 py-4 text-left align-middle text-sm font-semibold">
                Original Purchase Date
              </th>
              <td className="whitespace-nowrap border-b border-gray-200 text-left align-middle text-sm font-light">
                {new Date(created).toLocaleDateString('en-EN')}
              </td>
            </tr>
            <tr>
              <th className="whitespace-nowrap border-b border-solid border-gray-200 py-4 text-left align-middle text-sm font-semibold">
                Associated Tenants
              </th>
              <td className="truncate whitespace-nowrap border-b border-gray-200 text-left align-middle text-sm font-light">
                {tenantIds.split(';').join(', ')}
              </td>
            </tr>
            <tr>
              <th className="whitespace-nowrap border-b border-solid border-gray-200 py-4 text-left align-middle text-sm font-semibold">
                Product Subscription
              </th>
              <td className="whitespace-nowrap border-b border-gray-200 text-left align-middle text-sm font-light">
                {SOHO_PRODUCT_TYPES.SHAREPOINT_TEAMS.title}
              </td>
            </tr>
            <tr>
              <th className="whitespace-nowrap border-b border-solid border-gray-200 py-4 text-left align-middle text-sm font-semibold">
                User Licenses [used/available]
              </th>
              <td className="whitespace-nowrap border-b border-gray-200 text-left align-middle text-sm font-light">
                {totalUser} / {maxAllowedUser} {trialUser && ' [Trial Enabled]'}
              </td>
            </tr>
          </tbody>
        </table>
      </GroupCard>
      <GroupCard title="Documents" className="h-full w-1/3">
        <a
          className="mb-2 flex items-center font-semibold text-blue-500 hover:underline"
          href="https://pdfkbase.sohodragon.nyc/"
          target="_blank"
          rel="noreferrer"
        >
          <FiBook className="mr-1 h-5 w-5" />
          Knowledge Base
        </a>
        <a
          className="flex items-center font-semibold text-blue-500 hover:underline"
          href="https://pdfkbase.sohodragon.nyc/"
          target="_blank"
          rel="noreferrer"
        >
          <MdOutlineAttachMoney className="h-6 w-6" />
          Payment Details - Update Billing
        </a>
      </GroupCard>
    </div>
  );
};
