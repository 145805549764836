import { RouteObject } from 'react-router-dom';
import {
  Admin,
  Editor,
  Registration,
  Forbidden,
  NotFound,
  Layout
} from '@pages';
import { APP_ROUTE } from '@constants';

export const routeConfig: RouteObject[] = [
  {
    path: APP_ROUTE.ROOT,
    element: <Layout />,
    children: [
      {
        path: APP_ROUTE.EDITOR,
        index: true,
        element: <Editor />
      },
      {
        path: APP_ROUTE.ADMIN,
        element: <Admin />
      },
      {
        path: APP_ROUTE.REGISTRATION,
        element: <Registration />
      },
      {
        path: APP_ROUTE.FORBIDDEN,
        element: <Forbidden />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];
