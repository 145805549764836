import { TOAST_TYPE, APP_STATUS } from '@constants';
import { makeToast } from '@helpers';
import { Core } from '@pdftron/webviewer';
import {
  putCreateUploadSession,
  chunkingAndUploadFile,
  validateActions
} from '@services';
import { Subscription, Saveoptions } from '@types';
import toast from 'react-hot-toast';

export const saveDocument = async (
  annotationManager: Core.AnnotationManager,
  document: Core.Document,
  subscription: Subscription,
  tenantId: string,
  tenantUserEmail: string,
  driveId: string,
  fileId: string,
  saveoptions: Saveoptions
) => {
  const loadingToastId = makeToast({
    type: TOAST_TYPE.LOADING,
    message: APP_STATUS.INFO.SAVING_DOCUMENT,
    loading: false
  });

  const isActionsValid = await validateActions(
    subscription,
    tenantId,
    tenantUserEmail
  );

  if (isActionsValid) {
    const [uploadSession, xfdfString] = await Promise.all([
      putCreateUploadSession(driveId, fileId),
      annotationManager.exportAnnotations()
    ]);

    const uploadUrl = uploadSession.uploadUrl as string;

    if (!uploadUrl) {
      toast.dismiss(loadingToastId);

      makeToast({
        message: APP_STATUS.ERROR.PDF_SAVED_MESSAGE,
        type: TOAST_TYPE.ERROR
      });
      return;
    }
    const options = { xfdfString, ...saveoptions };
    console.log(options);
    
    const fileData = await document.getFileData(options);

    const fileArray = new Uint8Array(fileData);
    const blobFile = new Blob([fileArray], { type: 'application/pdf' });

    await chunkingAndUploadFile(blobFile, uploadUrl, loadingToastId);
  }
};
