import { FunctionComponent, ReactNode } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import {
  AppMsalProvider,
  SubscriptionProvider,
  UserTenantProvider
} from '@contexts';

export type ProviderWrapperProps = {
  children: ReactNode;
};

export const ProviderWrapper: FunctionComponent<ProviderWrapperProps> = ({
  children
}) => {
  return (
    <NiceModal.Provider>
      <AppMsalProvider>
        <UserTenantProvider>
          <SubscriptionProvider>{children}</SubscriptionProvider>
        </UserTenantProvider>
      </AppMsalProvider>
    </NiceModal.Provider>
  );
};
