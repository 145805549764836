import { HTTP_METHOD } from '@constants';
import { hubspotExecutor } from '@services';

export const patchUpdateSubscriptionUserNum = async (
  dealId: string,
  numberOfUsers: number
) => {
  const endpoint = `/records/${dealId}`;

  const graphQuery = {
    properties: {
      no__users: numberOfUsers
    }
  };

  return hubspotExecutor(HTTP_METHOD.PATCH, endpoint, graphQuery);
};
