import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { LuSave } from 'react-icons/lu';
import { TiWarningOutline } from 'react-icons/ti';
import { useState } from 'react';
import { Switch } from '@headlessui/react';
import Tooltip from 'react-simple-tooltip';

export const SaveModal = NiceModal.create(() => {
  const modal = useModal();
  const cancelButtonRef = useRef(null);
  const [enabled, setEnabled] = useState(false);

  return (
    <Transition.Root show={modal.visible} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => modal.remove()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="flatten-file relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <LuSave className="text-green-600" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Save File
                    </Dialog.Title>
                    <div className="mt-2 flex items-center">
                      {/* <p className="text-sm text-gray-500"> */}
                      <Switch.Group>
                        <div className="py-3">
                          <Switch
                            checked={enabled}
                            onChange={setEnabled}
                            className={`${
                              enabled ? 'bg-green-600' : 'bg-gray-200'
                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                          >
                            <span className="sr-only">Flatten File</span>
                            <span
                              className={`${
                                enabled ? 'translate-x-6' : 'translate-x-1'
                              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                            />
                          </Switch>
                          <Switch.Label className="pl-4">
                            Flatten File
                          </Switch.Label>
                        </div>
                      </Switch.Group>
                      <Tooltip
                              color="#000"
                              background="#fff"
                              radius={8}
                              border="#e1e1e1"
                              padding={10}
                              zIndex={101}
                              content="Flattening will burn in all annotations to the file. This action cannot be undone."
                            >
                              <TiWarningOutline className="relative top-[-2px] ml-2 inline-block text-xl text-yellow-400" />
                       </Tooltip>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={() => {
                      modal.resolve({ flatten: enabled });
                      modal.hide();
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => modal.hide()}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});
