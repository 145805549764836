import { SyntheticEvent, useContext, useEffect, useRef, useState } from 'react';
import { BsFillCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs';
import { GroupCard } from '@components';
import { deleteAccount, getAccounts, patchAddAccount } from '@services';
import { SubscriptionContext, UserTenantContext } from '@contexts';
import { Account } from '@types';
import { isValidEmail, makeToast } from '@helpers';
import { APP_STATUS, TOAST_TYPE } from '@constants';
import toast from 'react-hot-toast';

export const AdminSettingsTab = () => {
  const { subscription } = useContext(SubscriptionContext);
  const userTenant = useContext(UserTenantContext);
  const emailInputRef = useRef<HTMLInputElement | null>(null);
  const [adminAccounts, setAdminAccounts] = useState<Account[]>([]);

  useEffect(() => {
    fetchAndSetAdmin();
  }, []);

  const fetchAndSetAdmin = async () => {
    const { subscriptionId } = subscription;
    const { tenantId } = userTenant;

    const loadingToastId = makeToast({
      type: TOAST_TYPE.LOADING,
      message: APP_STATUS.INFO.FETCHING_DATA,
      loading: true
    });

    const response = await getAccounts(subscriptionId, tenantId, 'Admin');

    toast.dismiss(loadingToastId);

    setAdminAccounts(response.value);
  };

  const handleDeleteAccount = async (adminEmail: string) => {
    const { tenantId } = userTenant;
    const confirm = window.confirm(APP_STATUS.WARN.ARE_YOU_SURE_DELETE_USER);

    if (confirm) {
      const loadingToastId = makeToast({
        type: TOAST_TYPE.LOADING,
        message: APP_STATUS.INFO.DELETING_ACCOUNT,
        loading: true
      });

      await deleteAccount(subscription, tenantId, adminEmail, 'Admin');

      toast.dismiss(loadingToastId);

      makeToast({
        type: TOAST_TYPE.SUCCESS,
        message: APP_STATUS.SUCCESS.ACCOUNT_DELETED
      });

      fetchAndSetAdmin();
    }
  };

  const handleAddAccount = async (event: SyntheticEvent) => {
    event.preventDefault();

    const { subscriptionId } = subscription;
    const { tenantId } = userTenant;
    const registerEmail = emailInputRef.current!.value;

    const isAccountExist = adminAccounts.find(
      (account) => account.Email === registerEmail
    );

    if (isAccountExist) {
      makeToast({
        type: TOAST_TYPE.ERROR,
        message: APP_STATUS.ERROR.USER_EXISTS
      });

      return;
    }

    if (!isValidEmail(registerEmail)) {
      makeToast({
        type: TOAST_TYPE.ERROR,
        message: APP_STATUS.ERROR.ADMIN_INVALID_EMAIL
      });

      return;
    }

    const loadingToastId = makeToast({
      type: TOAST_TYPE.LOADING,
      message: APP_STATUS.INFO.ADDING_ACCOUNT,
      loading: true
    });

    await patchAddAccount(
      subscriptionId,
      tenantId,
      registerEmail,
      'Admin',
      false
    );

    emailInputRef.current!.value = '';

    toast.dismiss(loadingToastId);

    makeToast({
      type: TOAST_TYPE.SUCCESS,
      message: APP_STATUS.SUCCESS.ACCOUNT_ADDED
    });

    fetchAndSetAdmin();
  };

  return (
    <div className="flex justify-between gap-8">
      <GroupCard title="Add Admin" section="Settings" className="h-full w-1/3">
        <form onSubmit={handleAddAccount}>
          <label
            htmlFor="email"
            className="mb-2 block text-sm  font-medium text-gray-600"
          >
            Email Adress
          </label>
          <input
            ref={emailInputRef}
            type="email"
            id="email"
            name="email"
            className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
            placeholder="mail@example.com"
            required
          />
          <button
            type="submit"
            className="mt-3 items-end rounded-lg bg-red-500 px-6 py-2 text-center text-white transition-colors hover:bg-red-700"
          >
            Add
          </button>
        </form>
      </GroupCard>
      <GroupCard title="Registered Admins" className="w-2/3">
        <table className="w-full border-collapse items-center bg-transparent">
          <thead>
            <tr>
              <th className="border-b border-solid border-gray-200 py-4 text-left align-middle text-sm font-semibold">
                Email Address
              </th>
              <th className="border-b border-solid border-gray-200 text-left align-middle text-sm font-semibold">
                Primary
              </th>
              <th className="border-b border-solid border-gray-200 text-left align-middle text-sm font-semibold"></th>
            </tr>
          </thead>
          <tbody>
            {adminAccounts.map((account) => {
              const { Email, PrimaryAdmin } = account;
              return (
                <tr key={Email}>
                  <td className="border-b border-gray-200 py-4 text-left align-middle text-sm font-light">
                    {Email}
                  </td>
                  <td className="border-b border-gray-200 text-left align-middle text-sm font-light">
                    {PrimaryAdmin ? (
                      <BsFillCheckCircleFill className="ml-4 h-5 w-5 text-green-500" />
                    ) : (
                      <BsFillXCircleFill className="ml-4 h-5 w-5 text-red-500" />
                    )}
                  </td>
                  <td className="align-right border-b border-gray-200 text-right text-sm font-light">
                    <button
                      type="submit"
                      disabled={PrimaryAdmin}
                      onClick={() => handleDeleteAccount(Email)}
                      className="items-end rounded-lg bg-red-500 px-4 py-2 text-center text-white transition-colors hover:bg-red-700 disabled:cursor-not-allowed disabled:bg-red-200"
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </GroupCard>
    </div>
  );
};
