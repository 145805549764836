import { SyntheticEvent, useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { GroupCard } from '@components';
import { TOAST_TYPE, APP_STATUS } from '@constants';
import { SubscriptionContext, UserTenantContext } from '@contexts';
import {
  GetMaximumAllowedUsers,
  isTrialUser,
  isValidEmail,
  makeToast
} from '@helpers';
import {
  deleteAccount,
  getAccounts,
  patchAddAccount,
  updateSubscriptionPlan,
  getAccountCount
} from '@services';
import { Account } from '@types';

export const UserSettingsTab = () => {
  const { subscription } = useContext(SubscriptionContext);
  const userTenant = useContext(UserTenantContext);
  const [subTotal, setSubTotal] = useState<number>(0);
  
  const emailInputRef = useRef<HTMLInputElement | null>(null);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [totalUser, setTotalUser] = useState(0); 
   
  useEffect(() => {
    fetchAndSetAccounts(); 
    setSubTotal( maxAllowedUser );   
  }, []);
  

  const formatNumber = (e :any ) => {        
    setSubTotal(  e.target.value =  e.target.value.trim().length === 0 ? 0 :  parseInt(e.target.value));        
  };
  
  const fetchAndSetAccounts = async () => {
    const { subscriptionId } = subscription;
    const { tenantId } = userTenant;

    const loadingToastId = makeToast({
      type: TOAST_TYPE.LOADING,
      message: APP_STATUS.INFO.FETCHING_DATA,
      loading: true
    });

    const response = await getAccounts(subscriptionId, tenantId, 'User');
    const value = await response.value;
    setAccounts(value);
    fetchAndSetTotalUser();
    toast.dismiss(loadingToastId);
  };

    
  const fetchAndSetTotalUser = async () => {
    const { subscriptionId  } = subscription;
    const loadingToastId = makeToast({
      type: TOAST_TYPE.LOADING,
      message: APP_STATUS.INFO.FETCHING_DATA,
      loading: true
    });
    
    const usersCount = await getAccountCount(subscriptionId);

    toast.dismiss(loadingToastId);
    if (usersCount?.value) {
      setTotalUser(usersCount.value.length);
    }
  };

  const handleDeleteAccount = async (adminEmail: string) => {
    const { tenantId } = userTenant;
    const confirm = window.confirm(APP_STATUS.WARN.ARE_YOU_SURE_DELETE_USER);

    if (confirm) {
      const loadingToastId = makeToast({
        type: TOAST_TYPE.LOADING,
        message: APP_STATUS.INFO.DELETING_ACCOUNT,
        loading: true
      });

      await deleteAccount(subscription, tenantId, adminEmail, 'User');

      toast.dismiss(loadingToastId);

      makeToast({
        type: TOAST_TYPE.SUCCESS,
        message: APP_STATUS.SUCCESS.ACCOUNT_DELETED
      });
      fetchAndSetAccounts();
    }
  };

  const handleAddUser = async (event: SyntheticEvent) => {
    event.preventDefault();

    const { subscriptionId } = subscription;
    const { tenantId } = userTenant;
    const registerEmail = emailInputRef.current!.value;

    if (totalUser >= maxAllowedUser) {
      makeToast({
        type: TOAST_TYPE.ERROR,
        message: APP_STATUS.ERROR.USER_LIMIT_REACHED
      });
      return;
    }

    const isAccountExist = accounts.find((account) => account.Email === registerEmail);

    if (isAccountExist) {
      makeToast({
        type: TOAST_TYPE.ERROR,
        message: APP_STATUS.ERROR.USER_EXISTS
      });
      return;
    }

    if (!isValidEmail(registerEmail)) {
      makeToast({
        type: TOAST_TYPE.ERROR,
        message: APP_STATUS.ERROR.ADMIN_INVALID_EMAIL
      });
      return;
    }

    const loadingToastId = makeToast({
      type: TOAST_TYPE.LOADING,
      message: APP_STATUS.INFO.ADDING_ACCOUNT,
      loading: true
    });

    await patchAddAccount(subscriptionId, tenantId, registerEmail, 'User');

    emailInputRef.current!.value = '';

    toast.dismiss(loadingToastId);

    makeToast({
      type: TOAST_TYPE.SUCCESS,
      message: APP_STATUS.SUCCESS.ACCOUNT_ADDED
    });

    fetchAndSetAccounts();
  };

  const handleUpdatePlan = async (event: SyntheticEvent) => {
    event.preventDefault();
    
    const { subscriptionId, dealId } = subscription;

    const currentNum = accounts.length;
    const isLowerThanRegisteredUser = currentNum > subTotal;

    if (isLowerThanRegisteredUser) {
      makeToast({
        type: TOAST_TYPE.ERROR,
        message: APP_STATUS.ERROR.REGISTED_USER_TOO_HIGH
      });
      return;
    }

    const loadingToastId = makeToast({
      type: TOAST_TYPE.LOADING,
      message: APP_STATUS.INFO.UPDATING_SUBSCRIPTION,
      loading: true
    });

    await updateSubscriptionPlan(subscriptionId, subTotal, dealId);
    subscription.numberOfUsers = subTotal;
    maxAllowedUser = subTotal;

    toast.dismiss(loadingToastId);
  };

  var maxAllowedUser = GetMaximumAllowedUsers(subscription);
  const trialUser = isTrialUser(subscription.created);

  return (
    <>
      <div className="flex justify-between gap-8">
        <GroupCard title="Add User" section="Settings" className="h-full w-1/3">
          <form onSubmit={handleAddUser}>
            <label
              htmlFor="email"
              className="mb-2 block text-sm font-medium text-gray-600"
            >
              Email Address
            </label>
            <input
              ref={emailInputRef}
              type="email"
              id="email"
              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500"
              placeholder="mail@example.com"
              required
            />
            <button
              type="submit"
              className="mt-3 rounded-lg bg-red-500 px-6 py-2 text-center text-white transition-colors hover:bg-red-700"
            >
              Add
            </button>
          </form>
        </GroupCard>
        <GroupCard title="Registered Users" className="w-2/3">
          <table className="w-full border-collapse items-center bg-transparent">
            <thead>
              <tr>
                <th className="whitespace-nowrap border-b border-solid border-gray-200 py-4 text-left text-sm font-semibold">
                  Email Address
                </th>
                <th className="whitespace-nowrap border-b border-solid border-gray-200 text-left text-sm font-semibold"></th>
              </tr>
            </thead>
            <tbody>
              {accounts.map((account) => {
                const { Email } = account;
                return (
                  <tr key={Email}>
                    <td className="whitespace-nowrap border-b border-gray-200 py-4 text-left text-sm font-light">
                      {Email}
                    </td>
                    <td className="whitespace-nowrap border-b border-gray-200 text-right text-sm font-light">
                      <button
                        type="submit"
                        onClick={() => handleDeleteAccount(Email)}
                        className="rounded-lg bg-red-500 px-4 py-2 text-center text-white transition-colors hover:bg-red-700"
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </GroupCard>
      </div>
      <GroupCard
        title="Subscription Details"
        section="Settings"
        className="mt-16 h-full w-2/3"
      >
        <div className="flex justify-between gap-8">
          <div className="w-full">
            <label
              htmlFor="sub"
              className="mb-2 block text-sm font-medium text-gray-600"
            >
              Subscription
            </label>
            <input
              type="text"
              id="sub"
              className="block w-full rounded-lg border border-gray-300 bg-gray-100 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:hover:cursor-not-allowed"
              defaultValue="PDF88 SharePoint and Teams"
              disabled
            />
          </div>
          <div className="w-full">
            <form onSubmit={handleUpdatePlan}>
              <label
                htmlFor="sub-total"
                className="mb-2 block text-sm font-medium text-gray-600"
              >
                Subscription Total
              </label>
              <input
                disabled={trialUser} 
                onChange={formatNumber}
                 
                value={subTotal}
                type="number"
                id="sub-total"
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 disabled:hover:cursor-not-allowed"
                required
              />
              <button
                type="submit"
                //disabled={ isNaN(subTotal) &&  (Number(subTotal) === maxAllowedUser || Number(subTotal) >= accounts.length)}
                disabled={   Number(subTotal) < totalUser }
                className="my-3 rounded-lg bg-red-500 px-6 py-2 text-center text-white transition-colors hover:bg-red-700 disabled:cursor-not-allowed disabled:bg-red-200"
              >
                 Update  
              </button>
            </form>
            <p className="text-xs font-bold text-red-500">
              <i>
                Important: Altering number of users will incur an additional
                cost!
              </i>
            </p>
          </div>
        </div>
      </GroupCard>
    </>
  );
};
