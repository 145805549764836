/* eslint-disable promise/catch-or-return */
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SaveModal, EditorLoader } from '@components';
import { useModal } from '@ebay/nice-modal-react';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';
import {
  APP_ROUTE,
  APP_STATUS,
  PDF_VIEWER_ELEMENT_ID,
  REALTIME_ENABLE_DEFAULT,
  SEARCH_PARAMS_KEY,
  TOAST_TYPE,
  HUB_API, 
  HUB_ENABLE_DEFAULT
} from '@constants';
import {
  checkIsAdmin,
  getFile,
  saveDocument,
  sendAnnotationHub,
  loadAnnotations
} from '@services';
import { makeToast } from '@helpers';
import { SubscriptionContext, UserTenantContext } from '@contexts';
import toast from 'react-hot-toast';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel
} from '@microsoft/signalr';

export const Editor = () => {
  const saveModal = useModal(SaveModal);
  const { subscription } = useContext(SubscriptionContext);
  const { tenantUserDisplayName, tenantUserEmail, tenantUserId, tenantId } = useContext(UserTenantContext);
  const [loading, setLoading] = useState(true);
  const viewerInstanceRef = useRef<WebViewerInstance | null>();
  const fileInfoRef = useRef<any | null>();
  const navigate = useNavigate();

  const webApiUrl = `https://${subscription.annotationEndPoint}`;

  const hubApiUrl = webApiUrl + HUB_API;

  let connection: HubConnection,
    documentId: any = null,
    realTime: boolean = REALTIME_ENABLE_DEFAULT,
    hubEnabled: boolean = HUB_ENABLE_DEFAULT;

  const { search } = useLocation();

  const paramRealTime = new URLSearchParams(search.toLowerCase()).get(
    SEARCH_PARAMS_KEY.REAL_TIME.toLowerCase()
  );

  if (paramRealTime != null) {
    realTime = true;
  }

  if (subscription.annotationEndPoint) {
    hubEnabled = true;
  }

  if (hubEnabled) {
    connection = new HubConnectionBuilder()
      .withUrl(hubApiUrl)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    console.log('connecting...');
    connection
      .start()
      .then(() =>
        console.log(
          'Now connected, connection ID = ' + connection?.connectionId
        )
      )
      .catch((err) =>
        console.log('Error while establishing connection :( ' + err)
      );

    connection.onclose(() => {
      console.log('Connection disconnected');
    });
  }

  useEffect(() => {
    initWebviewer();
  }, []);

  const initWebviewer = async () => {
    const fileInfo = await getFile();
    const sourceAbsoluteFileUrl = fileInfo.webUrl;

    if (!fileInfo) return;

    const fileExt = fileInfo.name.split('.').pop();

    const viewerInstance = await WebViewer(
      {
        path: '/webviewer',
        useDownloader: false,
        extension: fileExt,
        fullAPI: true,
        licenseKey: process.env.REACT_APP_PDFTRON_LICENSE_KEY,
        enableRedaction: true,
        annotationUser: tenantUserDisplayName,
        initialDoc: fileInfo['@microsoft.graph.downloadUrl']
      },
      document.getElementById(PDF_VIEWER_ELEMENT_ID)!
    );

    const loadingToastId = makeToast({
      type: TOAST_TYPE.LOADING,
      message: APP_STATUS.INFO.FILE_IS_LOADING,
      loading
    });

    setLoading(false);

    viewerInstanceRef.current = viewerInstance;
    fileInfoRef.current = fileInfo;

    const { id } = fileInfoRef.current;
    documentId = id;

    const {
      Core: { annotationManager, documentViewer }
    } = viewerInstanceRef.current;

    appendSaveButton();
    appendAdminButton();

    if (hubEnabled) {
      annotationManager.addEventListener(
        'annotationChanged',
        async (annotations, action, options) => {
          if (options.imported) return;

          const formFields = annotations.map(
            (annotation: { isFormFieldPlaceholder: () => any }) =>
              annotation.isFormFieldPlaceholder()
          );
          if (formFields.some((field: boolean) => field === true)) return;

          const xfdfStrings = await annotationManager.exportAnnotationCommand();

          const annotationIds = annotations
            .map((row: { Id: string }) => row.Id)
            .join(', ');

          sendAnnotationHub(
            connection,
            action,
            documentId,
            annotations[0].Id,
            xfdfStrings,
            sourceAbsoluteFileUrl,
            tenantUserEmail,
            tenantUserDisplayName,
            tenantUserId,
            realTime,
            annotationIds
          );
        }
      );
    }

    viewerInstance.UI.addEventListener('documentLoaded', () => {
      toast.dismiss(loadingToastId);
      makeToast({
        type: TOAST_TYPE.SUCCESS,
        message: APP_STATUS.SUCCESS.FILE_IS_IMPORTED
      });
    });

    if (hubEnabled) {
      documentViewer.addEventListener('documentLoaded', async () => {
        const xfdfStringRows = await loadAnnotations(webApiUrl, documentId);
        xfdfStringRows.forEach(async (row: { xfdfString: string }) => {
          const annotat = await annotationManager.importAnnotationCommand(
            row.xfdfString
          );
          annotationManager.drawAnnotationsFromList(annotat);
        });

        if (realTime) {
          viewerInstance.UI.disableElements([
            'thumbRotateClockwise',
            'thumbDelete',
            'pageManipulationOverlayButton'
          ]);
          viewerInstance.UI.disableFeatures(['ThumbnailReordering']);
        }

        var userConnection: any = {};
        userConnection.User = tenantUserEmail;
        userConnection.Document = documentId;

        connection.invoke('JoinDocument', userConnection);
      });

      connection.on(
        'ReceiveAnnotationHub',
        async (user, receiveDocumentId, receiveXfdfString) => {
          if (receiveDocumentId === documentId) {
            const annotations = await annotationManager.importAnnotationCommand(
              receiveXfdfString
            );
            await annotationManager.drawAnnotationsFromList(annotations);
          }
        }
      );
    }
  };

  const appendSaveButton = () => {
    if (!viewerInstanceRef.current) return;
    if (realTime) return; //disable for real time

    const saveButton = {
      type: 'actionButton',
      img: '../assets/save-button.svg',
      onClick: handleSaveDocument
    };

    viewerInstanceRef.current?.UI.setHeaderItems((header) =>
      header.push(saveButton)
    );
  };

  const appendAdminButton = async () => {
    const adminAccount = await checkIsAdmin(
      subscription.subscriptionId,
      tenantId,
      tenantUserEmail
    );

    if (!adminAccount) return;

    const adminButton = {
      type: 'actionButton',
      img: '../assets/admin-button.svg',
      onClick: () => navigate(APP_ROUTE.ADMIN)
    };

    viewerInstanceRef.current?.UI.setHeaderItems((header) =>
      header.push(adminButton)
    );
  };

  const handleSaveDocument = async () => {
    saveModal.show().then(async (data: any) => {
      console.log(data);
      if (!viewerInstanceRef.current || !fileInfoRef.current) return;
      const {
        Core: { documentViewer, annotationManager }
      } = viewerInstanceRef.current;
      const {
        id,
        parentReference: { driveId }
      } = fileInfoRef.current;
      const document = documentViewer.getDocument();
      await saveDocument(
        annotationManager,
        document,
        subscription,
        tenantId,
        tenantUserEmail,
        driveId,
        id,
        data
      );
    });
  };

  return (
    <div id={PDF_VIEWER_ELEMENT_ID} className="h-screen">
      {loading && <EditorLoader />}
    </div>
  );
};
