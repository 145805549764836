import { MS_GRAPH_ENDPOINT, HTTP_METHOD } from '@constants';
import { MSDriveList } from '@types';
import { msGraphExecutor } from '@services';

export const getDriveList = async (
  siteId: string
): Promise<MSDriveList['value']> => {
  const url = `${MS_GRAPH_ENDPOINT.SITES}/${siteId}/drives?$select=sharepointids,name,id`;

  const lists: MSDriveList = await msGraphExecutor(HTTP_METHOD.GET, url);

  if (lists.value) return lists.value;
  return [];
};
