import toast from 'react-hot-toast';
import { AppToaster } from '@components';
import { TOAST_TYPE } from '@constants';

const DEFAULT_DURATION = 3000;

export type ToasterParams = {
  title?: string;
  message: string;
  type: TOAST_TYPE;
  duration?: number;
  loading?: boolean;
};

export const makeToast = ({
  title,
  message,
  type,
  duration = DEFAULT_DURATION,
  loading
}: ToasterParams) => {
  const content = AppToaster({
    type,
    title,
    message
  });

  if (loading) {
    return toast.loading(content, {
      className: 'app-toaster',
      icon: null
    });
  }

  toast(content, { duration, className: 'app-toaster' });
};
