import cn from 'classnames';
import { FunctionComponent, ReactNode } from 'react';

export type GroupCardProps = {
  title: string;
  section?: string;
  children: ReactNode;
  className?: string;
};

export const GroupCard: FunctionComponent<GroupCardProps> = ({
  title,
  section,
  children,
  className
}) => (
  <div className={cn('mt-10 rounded-xl bg-white p-4 shadow-md', className)}>
    <div className="-mt-10 mb-6 flex h-24 flex-col rounded-xl bg-red-500 px-8 py-4 shadow-2xl">
      {section && (
        <h6 className="mb-2 text-xs font-medium uppercase text-gray-200">
          {section}
        </h6>
      )}
      <h1 className="my-auto text-2xl text-white">{title}</h1>
    </div>
    <div className="p-4">{children}</div>
  </div>
);
