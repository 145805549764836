import { USER_TYPE } from '@constants';
import { Subscription } from '@types';
import { getAccount, getAccounts, patchAddAccount } from '@services';

export const validateUser = async (
  subscription: Subscription,
  tenantId: string,
  userEmail: string
) => {
  const { subscriptionId } = subscription;

  const userAccount = await getAccount(
    subscriptionId,
    tenantId,
    userEmail,
    USER_TYPE.User
  );

  if (userAccount.value.length >= 1) return true;

  const userAccounts = await getAccounts(
    subscriptionId,
    tenantId,
    USER_TYPE.User
  );
   
  const totalUsers = userAccounts.value.length;
  //alert(totalUsers + ' ' + userAccounts.value.length );
  if (totalUsers < subscription.numberOfUsers) {
    await patchAddAccount(subscriptionId, tenantId, userEmail, USER_TYPE.User);

    return true;
  } else {
    return false;
  }
}; 
