import { HTTP_METHOD } from '@constants';
import { recurlyExecutor } from '@services';

export const postRecurlySubscription = (
  recurlySubscriptionId: string,
  numberOfUsers: number
) => {

  const url = `/update/${recurlySubscriptionId}`; 

  return recurlyExecutor(HTTP_METHOD.POST, url, { 'quantity': numberOfUsers });
};
