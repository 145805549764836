export const LOCAL_STORAGE_KEY = {
  TENANT_ID: 'pdf_tenant_id',
  TENANT_NAME: 'pdf_tenant_name',
  USER_EMAIL: 'pdf_user_email',
  INK_SIGNATURES: 'pdf_ink_signatures',
  ENVIRONMENT_VARIABLES: 'pdf_environment_variables',
  CURRENT_USER_EMAIL: 'pdf_current_user_email',
  GRAPH_TOKEN: 'GRAPH_TOKEN',
  GRAPH_EXPIRES_TIME: 'GRAPH_EXPIRES_TIME'
};
