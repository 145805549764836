import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ProviderWrapper } from '@contexts';
import { initMSA, setupRefeshMsalToken } from '@services';

export const Layout = () => {
  initMSA();

  useEffect(() => {
    setupRefeshMsalToken();
  }, []);

  return (
    <ProviderWrapper>
      <Outlet />
    </ProviderWrapper>
  );
};
