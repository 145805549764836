import { APP_ROUTE } from '@constants';
import { Link } from 'react-router-dom';

export const NotFound = () => {
  return (
    <>
      <h1 className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 scale-y-125 font-mono text-xxl font-black text-gray-200">
        404
      </h1>
      <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-6xl font-bold text-gray-600">
        PAGE NOT FOUND
      </div>
      <Link
        to={APP_ROUTE.ROOT}
        className="fixed bottom-40 left-1/2 -translate-x-1/2 text-3xl text-blue-700 hover:underline"
      >
        Go home
      </Link>
    </>
  );
};
