import { TOAST_TYPE, APP_STATUS } from '@constants';
import { makeToast } from '@helpers';
import {
  postRecurlySubscription,
  patchUpdateSubscriptionUserNum
} from '@services';

export const updateSubscriptionPlan = async (
  subscriptionId: string,
  inputNum: number,
  dealId: string
) => {
  try {
    const hasRecurlyUpdateSuccess = await postRecurlySubscription(
      subscriptionId,
      inputNum
    );

    if (!hasRecurlyUpdateSuccess) {
      return;
    }

    await patchUpdateSubscriptionUserNum(dealId, inputNum);

    makeToast({
      type: TOAST_TYPE.SUCCESS,
      message: APP_STATUS.SUCCESS.SUBSCRIPTION_UPDATED
    });
  } catch (error) {
    makeToast({
      type: TOAST_TYPE.ERROR,
      message: APP_STATUS.ERROR.SUBSCRIPTION_UPDATED
    });
  }
};
