import { SESSION_STORAGE_KEY } from '@constants';
import {
  getFileFromOneDrive,
  getFileFromSharePoint,
  getListGraphIdByListId,
  getListGraphIdByName,
  getSessionStorageItem
} from '@services';

export const getFile = async () => {
  const driveFileId = getSessionStorageItem(SESSION_STORAGE_KEY.DRIVE_FILE_ID);
  const siteId = getSessionStorageItem(SESSION_STORAGE_KEY.SITE_ID);
  const libraryName = getSessionStorageItem(SESSION_STORAGE_KEY.LIBRARY_NAME);
  const libraryId = getSessionStorageItem(SESSION_STORAGE_KEY.LIBRARY_ID);
  const filePath = getSessionStorageItem(SESSION_STORAGE_KEY.FILE_PATH);

  if (driveFileId) {
    return getFileFromOneDrive(driveFileId);
  }

  if (siteId && filePath && (libraryName || libraryId)) {
    const listGraphId = libraryId ? await getListGraphIdByListId(siteId, libraryId) : await getListGraphIdByName(siteId, libraryName);
    return getFileFromSharePoint(siteId, listGraphId!, filePath);
  }
};
