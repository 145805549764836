import { FunctionComponent } from 'react';
import { MsalAuthenticationResult } from '@azure/msal-react';

export const LoginError: FunctionComponent<MsalAuthenticationResult> = ({
  error
}) => {
  return (
    <div>An Error Occurred: {error ? error.errorCode : 'unknown error'}</div>
  );
};
