import { SyntheticEvent, useContext, useState } from 'react';
import { SubscriptionContext, UserTenantContext } from '@contexts';
import { getSubscriptionByTenantId, registerTenant } from '@services';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTE, APP_STATUS, TOAST_TYPE } from '@constants';
import { makeToast } from '@helpers';

export const Registration = () => {
  const { fetchAndSetSubscription } = useContext(SubscriptionContext);
  const { tenantId, tenantUserEmail } = useContext(UserTenantContext);
  const [subscriptionId, setSubscriptionId] = useState('');
  const [email, setEmail] = useState('');
  const [hasAgreedTU, setHasAgreedTU] = useState(false);
  const [hasAgreedPS, setHasAgreedPS] = useState(false);
  const [registering, setRegistering] = useState(false);
  const navigate = useNavigate();
  
  const handleRegistration = async (e: SyntheticEvent) => {

    setRegistering(true); 

    e.preventDefault();
    
    const result = await registerTenant(
      subscriptionId,
      email,
      tenantId,
      tenantUserEmail
    );

    if (result) {  
      makeToast({
        type: TOAST_TYPE.INFO,
        title: APP_STATUS.INFO.REGISTRATION_VERIFY_TITLE,
        message: APP_STATUS.INFO.REGISTRATION_VERIFY_BODY,
        duration: 15000
      });

      let retryCount = 0;
      const maxRetries = 10;
    
      while (retryCount < maxRetries) {
        const validateRegistration = await getSubscriptionByTenantId(tenantId);
        if (validateRegistration) {
          await fetchAndSetSubscription();
          break;
        } else {
          retryCount++;
          await new Promise(resolve => setTimeout(resolve, 5000)); // Wait for 5 second before retrying
        }
      }

      if (retryCount === maxRetries) {
        makeToast({
          type: TOAST_TYPE.ERROR,
          title: APP_STATUS.ERROR.REGISTRATION_VERIFY_FAILED_TITLE,
          message: APP_STATUS.ERROR.REGISTRATION_VERIFY_FAILED_BODY
        });
      } else {
        navigate(APP_ROUTE.EDITOR);
      }
    }

    setRegistering(false);
  };

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4">
      <div className="w-full max-w-md">
        <div>
          <img
            className="mx-auto h-20 w-auto"
            src="/images/logo.png"
            alt="logo"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Verify PDF88 Registration
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{' '}
            <a
              href="https://sohodragon.nyc/pdf-markup-tool/"
              target="_blank"
              rel="noreferrer"
              className="font-medium text-red-500 hover:underline"
            >
              start your 30-day free trial
            </a>
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleRegistration}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="-space-y-px rounded-md shadow-sm">
            <div>
              <label htmlFor="subscription-id" className="sr-only">
                Subscription ID
              </label>
              <input
                onChange={({ target: { value } }) => setSubscriptionId(value)}
                id="subscription-id"
                name="id"
                type="text"
                required
                className="focus:outline-none relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Subscription ID"
              />
            </div>
            <div>
              <label htmlFor="email" className="sr-only">
                Email Address
              </label>
              <input
                onChange={({ target: { value } }) => setEmail(value)}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="focus:outline-none relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Email Address"
              />
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="mb-2 flex self-baseline">
              <input
                onChange={({ target: { checked } }) => setHasAgreedTU(checked)}
                id="terms-of-use"
                name="terms-of-use"
                type="checkbox"
                defaultChecked={false}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <label
                htmlFor="terms-of-use"
                className="ml-2 block text-sm text-gray-900"
              >
                I have read and accept with the{' '}
                <a
                  className="text-red-500 hover:underline"
                  href="https://sohodragon.nyc/terms-of-use/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use Policy
                </a>
              </label>
            </div>
            <div className="flex self-baseline">
              <input
                onChange={({ target: { checked } }) => setHasAgreedPS(checked)}
                id="privacy-policy"
                name="privacy-policy"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <label
                htmlFor="privacy-policy"
                className="ml-2 block text-sm text-gray-900"
              >
                I confirm that I have read and agree to{' '}
                <a
                  className="text-red-500 hover:underline"
                  href="https://sohodragon.nyc/pdf-application-privacy-statement/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Statement
                </a>
              </label>
            </div>
          </div>
          <div>
            <button
              type="submit"
              disabled={registering || !hasAgreedTU || !hasAgreedPS}
              className="focus:outline-none group relative flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white hover:bg-red-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-200"
            >
              Register
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
