import { MSDriveList } from '@types';
import { getDriveList } from './get-drive-list';

export const getListGraphIdByListId = async (
  siteId: string,
  listId: string
) => {
  const driveList: MSDriveList['value'] = await getDriveList(siteId);

  const drive = driveList.find((d) => d.sharePointIds.listId === listId);

  if (drive) return drive.id;
};
