import { MS_GRAPH_ENDPOINT, HTTP_METHOD } from '@constants';
import { msGraphExecutor } from '@services';

export const getFileFromSharePoint = async (
  siteId: string,
  listId: string,
  relativeFilePath: string
) => {
  const encRelativeFilePath = encodeURIComponent(relativeFilePath);
  const url = `${MS_GRAPH_ENDPOINT.SITES}/${siteId}/drives/${listId}/root:${encRelativeFilePath}`;
  return msGraphExecutor(HTTP_METHOD.GET, url);
};
