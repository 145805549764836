import {
  HTTP_METHOD,
  AZ_STORAGE_BASE_URL,
  APP_STATUS,
  TOAST_TYPE
} from '@constants';
import { makeToast } from '@helpers';

export const storageExecutor = async (
  method: HTTP_METHOD,
  query: string,
  payload?: unknown,
  headerOptions?: HeadersInit
) => {
  try {
    const headers: HeadersInit = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headerOptions
    };

    const options: RequestInit = {
      method: method,
      headers: headers,
      body: JSON.stringify(payload)
    };

    const response = await fetch(`${AZ_STORAGE_BASE_URL}${query}`, options);

    if (response.status === 204) return response;
    return await response.json();
  } catch (error) {
    makeToast({
      type: TOAST_TYPE.ERROR,
      title: APP_STATUS.ERROR.AZURE_SERVER_ERROR_TITLE,
      message: APP_STATUS.ERROR.AZURE_SERVER_ERROR_BODY
    });
    throw error;
  }
};
