import { APP_STATUS, LOCAL_STORAGE_KEY, TOAST_TYPE } from '@constants';
import { makeToast } from '@helpers';
import { silentAcquireGrapthToken, getLocalStorageItem } from '@services';

export const setupRefeshMsalToken = () => {
  setInterval(async () => {
    const expiresTime = getLocalStorageItem(
      LOCAL_STORAGE_KEY.GRAPH_EXPIRES_TIME
    );

    const remainingTime =
      new Date(expiresTime).getTime() - new Date().getTime();

    if (remainingTime > 0) {
      return;
    }

    const hasRefeshSuccess = await silentAcquireGrapthToken();

    if (!hasRefeshSuccess) {
      makeToast({
        type: TOAST_TYPE.WARNING,
        duration: 10000,
        title: APP_STATUS.WARN.SESSION_EXPIRED_TITLE,
        message: APP_STATUS.WARN.SESSION_EXPIRED_BODY
      });
    }
  }, 10000);
};
