import { HTTP_METHOD } from '@constants';
import { storageExecutor } from '@services';

export const getAccounts = async (
  subscriptiondId: string,
  tenantId: string,
  accountType: string
) => {
  const url = `/get?$filter=PartitionKey eq '${subscriptiondId};${tenantId}' and AccountType eq '${accountType}'`;
  return storageExecutor(HTTP_METHOD.GET, url);
};
