const isSupport = () => {
  try {
    return 'sessionStorage' in window && window.sessionStorage !== null;
  } catch {
    console.error('sessionStorage support is not detected');
    return false;
  }
};

export const getSessionStorageItem = (key: string) => {
  if (isSupport()) {
    const item = window.sessionStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    }
  }
  return null;
};

export const setSessionStorageItem = (key: string, value: unknown) => {
  if (isSupport()) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }
};
