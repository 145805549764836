import { GRAPH_SCOPES, LOCAL_STORAGE_KEY } from '@constants';
import { setLocalStorageItem } from '@services';
import { msalInstance } from '@services';

export const silentAcquireGrapthToken = async () => {
  const currentAccount = msalInstance.getActiveAccount();

  if (currentAccount) {
    try {
      const { accessToken, expiresOn } = await msalInstance.acquireTokenSilent({
        scopes: GRAPH_SCOPES,
        account: currentAccount
      });

      setLocalStorageItem(LOCAL_STORAGE_KEY.GRAPH_TOKEN, accessToken);
      setLocalStorageItem(LOCAL_STORAGE_KEY.GRAPH_EXPIRES_TIME, expiresOn);

      return true;
    } catch (error) {
      return false;
    }
  }

  return false;
};
