import { HTTP_METHOD, HUBSPOT_ENDPOINT } from '@constants';
import {
  getRegisteredTenantCount,
  transformEnterpriseAgreement
} from '@helpers';
import { hubspotExecutor } from '@services';
import { Subscription } from '@types';

export const getSubscriptionByRecurly = async (
  subscriptionId: string,
  userEmail: string
): Promise<Subscription | null> => {
  const graphQuery = {
    properties: [
      'tenant_id',
      'recurly_subscription_id',
      'recurly_admin',
      'recurly_account_code',
      'product_enterprise_agreement',
      'no__users',
      'dealstage',
      'annotation_end_point'
    ],
    filterGroups: [
      {
        filters: [
          {
            propertyName: 'recurly_admin',
            operator: 'EQ',
            value: userEmail
          },
          {
            propertyName: 'recurly_subscription_id',
            operator: 'EQ',
            value: subscriptionId
          }
        ]
      }
    ]
  };

  const responseObj = await hubspotExecutor(
    HTTP_METHOD.POST,
    HUBSPOT_ENDPOINT.SEARCH,
    graphQuery
  );

  const [result] = responseObj.results;
  if (!result) return null;

  const {
    id,
    properties: {
      tenant_id = '',
      no__users,
      product_enterprise_agreement,
      recurly_account_code,
      recurly_admin,
      recurly_subscription_id,
      createdate,
      dealstage,
      annotation_end_point
    }
  } = result;

  const enterpriseAgreement = transformEnterpriseAgreement(
    product_enterprise_agreement
  );

  const registeredTenantNum = getRegisteredTenantCount(tenant_id);

  const subscription: Subscription = {
    dealId: id,
    tenantIds: tenant_id,
    numberOfUsers: no__users,
    enterpriseAgreement: enterpriseAgreement,
    productCode: recurly_account_code,
    adminAccount: recurly_admin,
    subscriptionId: recurly_subscription_id,
    created: createdate,
    dealStage: dealstage,
    registeredTenantNum,
    isValid: !!recurly_subscription_id?.length,
    annotationEndPoint: annotation_end_point
  };
   
  return subscription;
};
