import { CONFIG_TRIAL_NUMBER_OF_DAYS } from '@constants';

export const isTrialUser = (trialStartDate: Date) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const today = new Date();

  const diffDays = Math.floor(
    (Date.parse(today.toString()) - Date.parse(trialStartDate.toString())) /
      oneDay
  );

  const isTrialActive = diffDays <= CONFIG_TRIAL_NUMBER_OF_DAYS;

  return isTrialActive;
};
