export const transformEnterpriseAgreement = (
  enterpriseAgreement: string | boolean
) => {
  switch (typeof enterpriseAgreement) {
    case 'string':
      return enterpriseAgreement.toLowerCase() === 'yes' ? true : false;
    case 'boolean':
      return enterpriseAgreement;
    default:
      return false;
  }
};
