import { MAX_TENANTS_NUM } from '@constants';

export const APP_STATUS = {
  SUCCESS: {
    PDF_SAVED_MESSAGE: 'File is successfully saved',
    PDF_EXPORT_TITLE: 'File exported successfully',
    REGISTRATION_COMPLETE: 'Registration Complete',
    ADMIN_ADDED: "The account with email '{0}' was added as an Admin",
    ADMIN_DELETED: "The account with email '{0}' was deleted",
    USER_ADDED: "The account with email '{0}' was added as a User",
    USER_DELETED: "The account with email '{0}' was deleted",
    SUBSCRIPTION_UPDATED: 'Subscription updated successfully',
    FILE_IS_IMPORTED: 'File imported successfully!',
    ACCOUNT_DELETED: 'Account successfully deleted',
    ACCOUNT_ADDED: 'Account successfully added!'
  },
  ERROR: {
    LOAD_FAILED_MESSAGE: 'Please refresh the page and try again',
    CONNECTION_ERROR: 'Unable to connect to the annotation database',
    PDF_SAVED_MESSAGE: 'File could not be saved',
    ADMIN_INVALID_EMAIL: 'Not a valid email address',
    USER_LIMIT_REACHED: 'User Limit Reached!',
    ADMIN_MAX_NUMBER_OF_USERS_BODY:
      'Increase the number of user licenses to add new users',
    INSUFFICIENT_USER_LICENSES_TITLE: 'Insufficient User Licenses',
    INSUFFICIENT_USER_LICENSES_BODY:
      'For you to save or export the pdf contact your administrator to upgrade the subscription',
    USER_EXISTS: 'The entered email already exists',
    REGISTRATION_MISMATCH_TITLE: 'Subscription Not Found',
    REGISTRATION_MISMATCH_BODY:
      'Please validate the information you entered is correct and try again',
    REGISTRATION_EXISTS:
      'This registration is already verified, so loading PDF!',
    REGISTRATION_ISSUE_TITLE: 'Unable to complete registration',
    REGISTRATION_ISSUE_BODY: 'Please contact support for further assistance',
    GRAPH_PERMISSIONS:
      'Unable to connect with graph for tenant/user information',
    GENERIC: 'Error opening PDF file',
    SUBSCRIPTION_INACTIVE_TITLE: "You're subscription is no longer active",
    SUBSCRIPTION_INACTIVE_BODY:
      'Check with your administrator to validate the subscription is current',
    SUBSCRIPTION_NOT_FOUND_TITLE: 'Subscription Not Found',
    SUBSCRIPTION_NOT_FOUND_BODY:
      'Contact technical support for assistance with this issue',
    SUBSCRIPTION_UPDATE_FAILED:
      'Unable to update subscription, try again later',
    SUBSCRIPTION_PARTIAL_UPDATE_FAILED:
      'The was a problem updating the subscription. Please refresh the page before trying again',
    SUBSCRIPTION_NUMBER_OF_CURRENT_USERS_GT_SUBSCRIPTION_TOTAL_TITLE:
      'The current number of registered users exceeds subscription total',
    REGISTRATION_VERIFY_FAILED_TITLE: 'Unable to verify subscription',
    REGISTRATION_VERIFY_FAILED_BODY: 'Please refresh and try again',
    REGISTED_USER_TOO_HIGH:
      'You need to lower the registered users number to set this',
    REGISTRATION_EXEEDED_NUMBER_OF_TENTANTS_TITLE:
      'Exceeded the number of tenant registrations',
    REGISTRATION_EXEEDED_NUMBER_OF_TENTANTS_BODY: `The current plan can only be registered to a maximum of ${MAX_TENANTS_NUM} tenants`,
    REGISTRATION_TERMS_OF_USE_POLICY_TITLE: 'Terms of Use Unchecked',
    REGISTRATION_TERMS_OF_USE_POLICY_BODY:
      'You must accept the Terms of Use in order to register this application',
    REGISTRATION_PRIVACY_POLICY_TITLE: 'Privacy Policy Unchecked',
    REGISTRATION_PRIVACY_POLICY_BODY:
      'You must accept the Privacy Policy in order to register this application',
    SHAREPOINT_LICENSE_ONLY_TITLE: 'Sharepoint Only Subscription',
    SHAREPOINT_LICENSE_ONLY_BODY:
      "It's been detected that you've opened this file from Microsoft Team. Your current subscription only supports SharePoint. For you to save or export the pdf contact your administrator need to upgrade the subscription",
    EXCEEDED_USER_LIMIT_TITLE: 'Number of users exceed the limit',
    EXCEEDED_USER_LIMIT_BODY:
      'For you to save or export the pdf contact your administrator to upgrade the subscription',
    SUBSCRIPTION_EXPIRED_TITLE: 'Your subscription has expired',
    SUBSCRIPTION_EXPIRED_BODY:
      'Please contact your administrator to renew your subscription',
    SUBSCRIPTION_CANCELLED_TITLE: 'Your subscription has been cancelled',
    SUBSCRIPTION_CANCELLED_BODY:
      'Please contact your administrator to reactivate your subscription',
    SUBSCRIPTION_PAUSED_TITLE: 'Your subscription has been paused',
    SUBSCRIPTION_PAUSED_BODY:
      'Please contact your administrator to reactivate your subscription',
    OUTDATED_CLIENT_TITLE: 'Outdated Client Version',
    OUTDATED_CLIENT_BODY:
      "The current version installed in your SharePoint tenant is outdated. Please have your tenant administrator <a href='https://pdfkbase.sohodragon.nyc/installation-guide' target='_blank'>visit our site</a> to download and install the latest version. We've made a lot of improvements in security, performance and iOS device support.",
    NO_GRAPH_TOKEN: 'Unable to obtain MS Graph Token',
    CONTACT_SUPPORT:
      'Something unexpected has occurred. Please contact support for further assistance',
    ACCOUNT_ADDED:
      "Unable to add account with email '{0}'. Please try again later",
    ACCOUNT_DELETED:
      "Unable to delete account with email '{0}'. Please try again later",
    HUBSPOT_SERVER_ERROR_TITLE: 'Server Error',
    HUBSPOT_SERVER_ERROR_BODY: 'Error reaching hubspot server',
    MICROSOFT_SERVER_ERROR_TITLE: 'Server Error',
    MICROSOFT_SERVER_ERROR_BODY: 'Error reaching microsoft server',
    AZURE_SERVER_ERROR_TITLE: 'Server Error',
    AZURE_SERVER_ERROR_BODY: 'Error reaching azure server',
    RECURLY_SERVER_ERROR_TITLE: 'Server Error',
    RECURLY_SERVER_ERROR_BODY: 'Error reaching recurly server',
    SUBSCRIPTION_UPDATED: 'Couldnt update subscription!'
  },
  WARN: {
    CONFIRM_DELETE_USER: "Are you sure you want to delete '{0}'?",
    ARE_YOU_SURE_DELETE_USER: 'Are you sure you want to delete this user?',
    SESSION_EXPIRED_TITLE: 'Current login session expired',
    SESSION_EXPIRED_BODY:
      'Please refesh the page / you will not be able to save this document'
  },
  INFO: {
    REGISTRATION_VERIFY_TITLE: 'Verifying Subscription',
    REGISTRATION_VERIFY_BODY: 'Hang tight while we validate the subscription, this can take up to 50 seconds. You will be redirected back to PDF88 automatically',
    SIGN_IN_TITLE: 'Launching PDF88',
    SIGN_IN_BODY:
      'Before we can access your files, you will need to authenticate. You are now being redirected to the Office 365 login screen. Upon successful login you will be redirected back to the PDF88 application',
    HELP: "For help with the application please visit our <a href='{0}' target='_blank'>knowledge base </a>",
    FILE_IS_LOADING: 'Importing document...',
    SAVING_DOCUMENT: 'Saving document...',
    ADDING_ACCOUNT: 'Adding acount...',
    DELETING_ACCOUNT: 'Deleting acount...',
    FETCHING_DATA: 'Fetching data...',
    UPDATING_SUBSCRIPTION: 'Updating subscription...'
  }
};
